import React, { useState } from 'react';
import PropTypes from 'prop-types';

const FacilityFilter = ({ facilities, facility, setFacility, label }) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className="relative"
      role="dialog"
      onMouseLeave={() => setOpen(false)}
      style={{ zIndex: open ? 51 : 0 }}
    >
      <span className="text-sm">{label}</span>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="h-16 pl-4 text-secondary group focus:outline-none"
      >
        <div className="px-3 py-2 transition border border-gray-300 rounded group-focus:border-black">
          <div className="flex items-center justify-center w-full h-full">
            <span className="text-sm capitalize">{facility.label}</span>
            <svg viewBox="0 0 8 5" className="flex-shrink-0 w-4 h-4 pl-2 fill-current">
              <path d="M3.64072 4.84701L0.143713 1.35001C-0.0479042 1.14641 -0.0479042 0.835035 0.143713 0.643418C0.335329 0.451802 0.646707 0.451802 0.850299 0.643418L4 3.79312L7.1497 0.643418C7.34132 0.451802 7.66467 0.451802 7.85629 0.643418C8.0479 0.835035 8.0479 1.14641 7.85629 1.35001L4.34731 4.84701C4.15569 5.03863 3.84431 5.03863 3.64072 4.84701Z" />
            </svg>
          </div>
        </div>
      </button>
      <div
        className="absolute bg-white border rounded"
        style={{ top: '3.75rem', display: open ? 'block' : 'none' }}
      >
        <ul className="flex flex-col items-center w-48">
          {facilities.map((s, i) => (
            <li key={s.label} className="w-full">
              <button
                type="button"
                tabIndex={open ? '0' : '-1'}
                onClick={() => {
                  setFacility(s);
                  setOpen(false);
                }}
                onBlur={i === facilities.length - 1 ? () => setOpen(false) : () => {}}
                className="flex items-center justify-center w-full py-2 whitespace-nowrap hover:bg-secondary hover:bg-opacity-10 focus:bg-secondary focus:bg-opacity-10 focus:outline-none"
              >
                {s.label}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

FacilityFilter.propTypes = {
  facilities: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      func: PropTypes.func.isRequired,
    }).isRequired
  ).isRequired,
  facility: PropTypes.shape({
    label: PropTypes.string.isRequired,
    func: PropTypes.func.isRequired,
  }).isRequired,
  setFacility: PropTypes.func.isRequired,
  label: PropTypes.string,
};

FacilityFilter.defaultProps = {
  label: 'Sort by',
};

export default FacilityFilter;
