import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { useLocation } from '@reach/router';

import { setAuthenticating, signOut } from 'src/store';
import CONFIG from 'src/config';
import { FLOWS } from 'src/config/shared';
import { Button } from 'src/components';
import EquilibreLogo from 'src/assets/images/Equilibre_LP_EquilibreLogo.png';

import Menu from './Menu';

const { GATSBY_DEPLOYMENT } = process.env;

const Navigation = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { pathname, search } = useLocation();

  const isLoginPage = pathname === '/';

  // trick to allow signup when passing ?signup
  const signupAllowed = search === '?signup' ||
    (GATSBY_DEPLOYMENT !== 'PROD' && GATSBY_DEPLOYMENT !== 'QA');

  const { instance, inProgress } = useMsal();

  const { t, i18n } = useTranslation('equilibre');
  const [menuOpen, setMenuOpen] = useState(false);
  const [language, setLanguage] = useState('en-US');

  if (language !== 'fr-FR') {
    i18n.changeLanguage('fr-FR');
    setLanguage('fr-FR');
  }

  const sign = async (signup = false) => {
    localStorage.setItem('redirect', 'true');
    dispatch(setAuthenticating(true));

    const redirectRequest = CONFIG.FLOWS[signup ? FLOWS.SIGNUP : FLOWS.SIGNIN];
    const lang = i18n.language.slice(0, 2);

    await instance.loginRedirect({
      ...redirectRequest,
      extraQueryParameters: { lang },
    });
  };

  const handleSignout = async () => {
    dispatch(signOut())
      .then(async () => instance.logout())
      .catch(async () => instance.logout());
  };

  return (
    <>
      <nav className="fixed inset-x-0 top-0 z-50 bg-white shadow-md">
        <div className="md:container">
          <div className="flex items-center justify-between h-16">
            <div>
              <div className="flex items-center">
                <div>
                  <img src={EquilibreLogo} alt="Sharecare | HealthSecurity" className="h-6" />
                </div>
              </div>
            </div>
            <div className={`items-center ${isLoginPage ? 'hidden xl:flex' : ''}`}>
              {isLoginPage && (
                <div className="flex items-center justify-end h-16 pl-8 space-x-4">
                  {user.authenticated ? (
                    <>
                      <Button as="link" variant="secondary" to="/dashboard/">
                        {t('navigation.buttons.dashboard')}
                      </Button>
                      <Button onClick={() => handleSignout()}>
                        {t('navigation.buttons.signout')}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => sign(false)}
                        variant="secondary"
                        gtag="Click_signin"
                        disabled={user.isAuthenticating || inProgress !== 'none'}
                        loading={user.isAuthenticating || inProgress !== 'none'}
                      >
                        {t('navigation.buttons.signin')}
                      </Button>
                      {signupAllowed && (
                        <Button
                          onClick={() => sign(true)}
                          gtag="Click_signup"
                          disabled={user.isAuthenticating || inProgress !== 'none'}
                          loading={user.isAuthenticating || inProgress !== 'none'}
                        >
                          {t('navigation.buttons.getStarted')}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
            {isLoginPage && (
              <div className="flex items-center xl:hidden">
                <button
                  type="button"
                  onClick={() => setMenuOpen(true)}
                  className="p-1 transition hover:text-primary-light-hover focus:text-primary-light-hover focus:outline-none"
                >
                  <svg viewBox="0 0 24 24" className="w-6 h-6 fill-current">
                    <path d="M20 8H4V6h16v2zm0 5H4v-2h16v2zm0 5H4v-2h16v2z" />
                  </svg>
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>
      <Menu
        open={menuOpen}
        requestClose={() => setMenuOpen(false)}
        sign={sign}
        signOut={handleSignout}
      />
    </>
  );
};

export default Navigation;
