import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

import { httpClient } from 'src/helpers';
import VERTICALS from 'src/constants/verticals.json';
import DashboardLayout from './app/layout';

import FtgLayout from './static/ftg/layout';
import VenueLayout from './static/venue/layout';
import VaccineLayout from './static/vaccine/layout';
import HospitalityLayout from './static/hospitality/layout';
import CarsLayout from './static/cars/layout';
import SchoolsLayout from './static/schools/layout';
import CruisesLayout from './static/cruises/layout';
import GifenLayout from './static/gifen/layout';
import VulcainLayout from './static/vulcain/layout';
import DekraLayout from './static/dekra/layout';
import GreenshieldLayout from './static/greenshield/layout';
import EquilibreLayout from './static/equilibre/layout';

const Layout = ({ children, dashboardLayout }) => {
  const { search } = useLocation();
  const [endorserData, setEndorserData] = useState({ logo: null });

  const fetchEndorser = async () => {
    const { ref } = queryString.parse(search);
    const { data, status } = await httpClient.get(`/endorsers/${ref || 'default'}`);

    if (status === 200) setEndorserData(data);
  };

  useLayoutEffect(() => {
    fetchEndorser();
  }, []);

  if (dashboardLayout) return <DashboardLayout>{children}</DashboardLayout>;

  switch (process.env.GATSBY_VERTICAL) {
    case VERTICALS.FTG:
      return <FtgLayout>{children}</FtgLayout>;
    case VERTICALS.VENUE:
      return <VenueLayout>{children}</VenueLayout>;
    case VERTICALS.VACCINE:
      return <VaccineLayout>{children}</VaccineLayout>;
    case VERTICALS.HOSPITALITY:
      return <HospitalityLayout>{children}</HospitalityLayout>;
    case VERTICALS.CARS:
      return <CarsLayout>{children}</CarsLayout>;
    case VERTICALS.SCHOOLS:
      return <SchoolsLayout>{children}</SchoolsLayout>;
    case VERTICALS.CRUISES:
      return <CruisesLayout>{children}</CruisesLayout>;
    case VERTICALS.GIFEN:
        return <GifenLayout>{children}</GifenLayout>;
    case VERTICALS.VULCAIN:
      return <VulcainLayout>{children}</VulcainLayout>;
    case VERTICALS.DEKRA:
      return <DekraLayout>{children}</DekraLayout>;
    case VERTICALS.GREENSHIELD:
      return <GreenshieldLayout logo={endorserData.logo}>{children}</GreenshieldLayout>;
    case VERTICALS.EQUILIBRE:
      return <EquilibreLayout>{children}</EquilibreLayout>;
    default:
      return <FtgLayout>{children}</FtgLayout>;
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  dashboardLayout: PropTypes.bool,
};

Layout.defaultProps = {
  dashboardLayout: false,
};

export default Layout;
