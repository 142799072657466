import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';

import { setAuthenticating, signOut } from 'src/store';
import CONFIG from 'src/config';
import { FLOWS } from 'src/config/shared';
import { Button } from 'src/components';

import Menu from './Menu';

const { GATSBY_DEPLOYMENT } = process.env;

const Navigation = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const { instance, inProgress } = useMsal();

  const { t, i18n } = useTranslation('common');
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(-1);

  const sign = async (signup = false) => {
    localStorage.setItem('redirect', 'true');
    dispatch(setAuthenticating(true));

    const redirectRequest = CONFIG.FLOWS[signup ? FLOWS.SIGNUP : FLOWS.SIGNIN];
    const lang = i18n.language.slice(0, 2);

    await instance.loginRedirect({
      ...redirectRequest,
      extraQueryParameters: { lang },
    });
  };

  const handleSignout = async () => {
    dispatch(signOut())
      .then(async () => instance.logout())
      .catch(async () => instance.logout());
  };

  return (
    <>
      <nav className="fixed inset-x-0 top-0 z-50 bg-white shadow-md">
        <div className="container">
          <div className="flex items-center justify-between h-16">
            <div>
              <Link to="/">
                <svg viewBox="0 0 410.848 40" className="w-56">
                  <g transform="translate(-1364.922 -6198)">
                    <g>
                      <path
                        d="M24.142,26.393A15.118,15.118,0,0,1,15,29.443,14.862,14.862,0,0,1,0,14.721,14.862,14.862,0,0,1,15,0,14.862,14.862,0,0,1,30,14.721a14.5,14.5,0,0,1-3.288,9.2l6.75,5.521H25.385ZM15,26.046A11.433,11.433,0,0,0,26.538,14.721,11.433,11.433,0,0,0,15,3.4,11.433,11.433,0,0,0,3.462,14.721,11.434,11.434,0,0,0,15,26.046Zm24.891-3.684h3.658c-.312,4.957,9.093,5.127,9.25,1.425,0-3.805-7.315-1.385-11.279-5.274-2.69-2.354-2.767-9.7,5.686-9.9a10.631,10.631,0,0,1,5.065,1.165V8.954h3.615V15.7H52.269V14.445a5.429,5.429,0,0,0-4.848-2.511c-2.2,0-3.653,1-3.653,2.511,0,3.418,8.017,1.426,11.454,4.8,4.2,4.54.663,10.7-6.21,10.464a9.409,9.409,0,0,1-5.465-1.469v1.126H39.89V22.362ZM62.054,3.547H58.31V0H66.2V11.288c2.6-2.075,4.667-2.94,6.916-2.94,3.258,0,5.728,1.6,5.728,6.185V25.821h3.572v3.547H74.7V15.4c0-1.989-.615-3.462-2.552-3.462-1.5,0-3.614.953-5.948,2.685v11.2h3.565v3.547h-11.5V25.821h3.79V3.547ZM104.663,29.37H97.436V26.857c-5.8,5.27-13.483,2.791-13.745-3.635-.016-4.656,5.835-9.237,13.262-4.885-.132-2.656.955-6.365-4.627-6.617a4.835,4.835,0,0,0-4.452,2.428L83.952,13.1c3.288-6.792,17.346-6.358,17.142,1.515v11.2h3.57v3.546Zm-7.71-7.615c-2.574-2.233-8.862-3.594-9.342,1.468.4,4.854,6.515,3.738,9.342.953V21.753Zm14.23-9.257H107V8.953h7.446V13.6A7.2,7.2,0,0,1,121.1,8.609h2.685v4.065h-2.2c-7.832.114-6.256,6.577-6.256,13.148h4.5v3.547H107.083V25.823h4.1V12.5Zm16.236,7.658c1.109,7.708,10.287,7.8,13.812,2.94l3.6,1.557c-6.24,9.285-22.064,5.859-21.923-5.492a10.809,10.809,0,0,1,10.87-10.812c5.747,0,11.053,4.327,11.053,11.807Zm13.242-2.945a7.031,7.031,0,0,0-6.794-5.622c-3.245,0-5.7,2.206-6.317,5.622Z"
                        transform="translate(1545 6203)"
                        fill="#333"
                        fillRule="evenodd"
                      />
                      <path
                        d="M22.154,11.409c0,2.97-3.018,6.876-8.078,10.182C9.3,18.478,6,14.34,6,11.409c.11-5.267,6.557-5.5,8.076-2.942C15.648,5.91,22.059,6.1,22.154,11.409ZM212.438,19.1c1.11,7.708,10.287,7.8,13.813,2.939l3.6,1.557c-6.24,9.286-22.064,5.859-21.923-5.492A10.809,10.809,0,0,1,218.795,7.289c5.746,0,11.052,4.327,11.052,11.808H212.438Zm-17.049-7.66H191.2V7.894h7.446V12.54a7.2,7.2,0,0,1,6.655-4.99h2.685v4.065h-2.2c-7.832.113-6.256,6.577-6.256,13.148h4.5V28.31H191.291V24.763h4.1V11.437ZM188.87,28.32h-7.228V25.807c-5.8,5.271-13.485,2.792-13.747-3.635-.015-4.655,5.835-9.235,13.263-4.884-.13-2.656.957-6.365-4.627-6.617a4.833,4.833,0,0,0-4.45,2.428l-3.923-1.044C171.446,5.264,185.5,5.7,185.3,13.569v11.2h3.57Zm36.811-12.168a7.033,7.033,0,0,0-6.8-5.622c-3.242,0-5.7,2.206-6.316,5.622ZM181.159,20.7c-2.573-2.232-8.862-3.593-9.34,1.469.393,4.853,6.513,3.738,9.34.953Zm-15.953-5.11h-3.7A5.2,5.2,0,0,0,156,10.967c-3.7,0-6.171,2.81-6.171,6.918.462,9.663,10.4,8.758,12.6,3.33l3.395,1.558a10.065,10.065,0,0,1-9.787,6.141c-7.223,0-10.615-5.8-10.615-10.985A10.407,10.407,0,0,1,155.645,7.291a9.055,9.055,0,0,1,5.862,1.988V7.895h3.7Z"
                        transform="translate(1545.923 6204.057)"
                        fill="#1aba9c"
                        fillRule="evenodd"
                      />
                    </g>
                    <g>
                      <path
                        d="M326.189-110.986a13.919,13.919,0,0,0-5.481-.991c-4.559,0-7.327,2.672-7.327,5.8,0,3.212,2.083,4.476,4.956,5.5,3.093,1.1,3.908,1.968,3.908,3.4a2.837,2.837,0,0,1-3.019,2.849c-2.289,0-4.016-1.346-5.344-5.059l-.93.242.162,5.306a19.027,19.027,0,0,0,6.409,1.126c4.83,0,7.767-2.36,7.767-6.188,0-2.57-1.256-3.98-4.425-5.209-3.423-1.325-4.612-2.161-4.612-3.745a2.51,2.51,0,0,1,2.524-2.7c2.2,0,3.7,1.367,4.689,4.569l.929-.241-.206-4.655"
                        transform="translate(1143.22 6317.325)"
                        fill="#231f20"
                      />
                      <path
                        d="M187.745-111.961a8.874,8.874,0,0,1-1.382-.133c-.129-1.747-.206-8.929-.2-15.208a5.278,5.278,0,0,1,1.892-.311c2.668,0,4.161,3.076,4.17,6.957.008,4.944-1.84,8.694-4.481,8.694m2.826-17.666a9.59,9.59,0,0,0-4.405,1.133c.016-3.945.065-7.388.16-8.689l-8.113,1.566v.73l.791.1c1.132.153,1.566.815,1.7,2.327.255,2.891.219,18.421,0,20.912a31.992,31.992,0,0,0,6.914.868c6.438,0,10.314-3.981,10.314-10.275,0-5.05-3.145-8.678-7.36-8.678"
                        transform="translate(1238.672 6335.182)"
                        fill="#231f20"
                      />
                      <path
                        d="M21.431-135.218H.1v1.025l1.136.158c1.628.234,2.253,1.24,2.445,3.539a126.025,126.025,0,0,1,0,16.056c-.192,2.3-.817,3.3-2.445,3.544L.1-110.742v1.028H13.808v-1.028l-1.44-.153c-1.626-.24-2.25-1.246-2.442-3.544-.131-1.561-.217-4.2-.246-7.3l2.826.092c1.888.061,2.677,1.459,3.13,3.528l1.058,0v-8.632H15.636c-.453,2.071-1.242,3.468-3.13,3.53l-2.832.09c-.022-4.076.054-7.992.252-10.343h4.209c3.288,0,5.045,2.086,6.148,5.776l1.265-.353-.117-7.173"
                        transform="translate(1364.853 6333.791)"
                        fill="#231f20"
                      />
                      <path
                        d="M73.726-110.8c-2.681,0-3.432,3.624-3.432,8.4,0,4.649,1.188,8.4,3.675,8.4,2.678,0,3.429-3.625,3.429-8.4,0-4.649-1.186-8.4-3.673-8.4m.314-1.175c6.066,0,9.078,4.11,9.078,9.529,0,5.29-3.4,9.627-9.466,9.627s-9.075-4.11-9.075-9.531c0-5.289,3.4-9.625,9.462-9.625"
                        transform="translate(1319.177 6317.322)"
                        fill="#231f20"
                      />
                      <path
                        d="M256.575-104.509l5.69-.12c.029-2.518-.373-6.116-2.422-6.116s-3.217,3.4-3.268,6.237m-5.649,2.308c0-5.015,3.16-9.735,9.412-9.735,5.086,0,7.514,3.77,7.541,8.7H256.534c-.125,4.473,2.139,7.762,6.477,7.762a5.932,5.932,0,0,0,4.091-1.356l.532.639a9.088,9.088,0,0,1-7.369,3.4,8.99,8.99,0,0,1-9.338-9.41"
                        transform="translate(1187.16 6317.297)"
                        fill="#231f20"
                      />
                      <path
                        d="M144.387-111.576c-2.048-1.223-5.778-.645-7.669,3.7l.1-4.231-8.085,1.568v.73l.788.1c1.129.156,1.565.819,1.7,2.33a78.719,78.719,0,0,1,0,10.557c-.136,1.507-.573,2.172-1.7,2.327l-.788.1v1.025h11.258v-1.025l-1.47-.1c-1.132-.155-1.567-.82-1.7-2.327a80.162,80.162,0,0,1-.057-9.8c.542-.895,3.187-1.616,5.643.032l1.983-4.982"
                        transform="translate(1273.729 6317.439)"
                        fill="#231f20"
                      />
                      <path
                        d="M387.681-105.758l2.824,2.053-1.078-3.323,2.825-2.052h-3.493l-1.078-3.322-1.08,3.322h-3.493l2.824,2.052-1.077,3.323,2.825-2.053"
                        transform="translate(1093.517 6317.627)"
                        fill="#231f20"
                      />
                      <path
                        d="M450.624-109.081l-1.079-3.321-1.08,3.321h-3.489l2.824,2.054-1.078,3.32,2.824-2.054,2.827,2.053-1.079-3.32,2.826-2.054h-3.494"
                        transform="translate(1049.693 6317.626)"
                        fill="#231f20"
                      />
                      <path
                        d="M400.585-72.709l-1.079-3.321-1.079,3.321h-3.491l2.825,2.054-1.078,3.32,2.823-2.054,2.826,2.054-1.079-3.32,2.826-2.054h-3.494"
                        transform="translate(1085.139 6291.86)"
                        fill="#231f20"
                      />
                      <path
                        d="M420.366-129.452l2.826-2.053H419.7l-1.079-3.321-1.079,3.321H414.05l2.826,2.053-1.082,3.321,2.826-2.053,2.826,2.053-1.081-3.321"
                        transform="translate(1071.599 6333.514)"
                        fill="#231f20"
                      />
                      <path
                        d="M442.253-72.712h-3.493l-1.08-3.322L436.6-72.712h-3.495l2.828,2.052-1.08,3.323,2.825-2.053,2.827,2.053-1.079-3.323,2.825-2.052"
                        transform="translate(1058.099 6291.862)"
                        fill="#231f20"
                      />
                      <path
                        d="M8.047-24.276H4.8v6.3H3.26v-6.3H0V-25.3H8.047v1.021"
                        transform="translate(1364.922 6255.92)"
                        fill="#231f20"
                      />
                      <path
                        d="M46.918-21.542h2.7a1.4,1.4,0,0,0,1.472-1.372,1.5,1.5,0,0,0-1.554-1.362H46.918Zm-1.54,3.57V-25.3h4.25a2.805,2.805,0,0,1,3.026,2.384,2.325,2.325,0,0,1-1.912,2.156l2.325,2.786H51.32L49.174-20.52H46.918v2.549h-1.54"
                        transform="translate(1332.778 6255.92)"
                        fill="#231f20"
                      />
                      <path
                        d="M92.917-24.008,91.294-20.82h3.191ZM88.35-17.972,92.284-25.3H93.55l3.92,7.326H95.833l-1.045-1.991H91.019L90-17.972H88.35"
                        transform="translate(1302.333 6255.92)"
                        fill="#231f20"
                      />
                      <path
                        d="M133.289-25.3l2.957,5.871,2.93-5.871H140.8l-3.893,7.326h-1.32L131.652-25.3h1.637"
                        transform="translate(1271.657 6255.92)"
                        fill="#231f20"
                      />
                      <path
                        d="M188.869-18.994v1.022h-6.7V-25.3h6.575v1.021h-5.034v2.084h4.36v.959h-4.36v2.239h5.158"
                        transform="translate(1235.869 6255.92)"
                        fill="#231f20"
                      />
                      <path
                        d="M225.9-17.972V-25.3h1.541v6.3h5.241v1.022H225.9"
                        transform="translate(1204.892 6255.92)"
                        fill="#231f20"
                      />
                      <path
                        d="M285.351-19.005a4.311,4.311,0,0,1-2.792.96c-2.545,0-4.539-1.827-4.539-3.746,0-1.991,1.967-3.683,4.594-3.683a4.259,4.259,0,0,1,3.728,1.6l-1.183.64a2.983,2.983,0,0,0-2.586-1.2,2.792,2.792,0,0,0-2.985,2.672,2.875,2.875,0,0,0,3.1,2.673,3.843,3.843,0,0,0,2.668-1.084v-.774H283.3V-21.8h3.343v3.7h-1.293v-.908"
                        transform="translate(1167.968 6256.045)"
                        fill="#231f20"
                      />
                      <path
                        d="M335.18-21.583V-25.3h1.554v3.715c0,2-1.21,3.663-4.223,3.663-3.109,0-4.209-1.764-4.209-3.663V-25.3h1.541v3.715a2.4,2.4,0,0,0,2.669,2.621,2.4,2.4,0,0,0,2.669-2.621"
                        transform="translate(1132.346 6255.92)"
                        fill="#231f20"
                      />
                      <path
                        d="M379.292-17.947v-7.316h1.541v7.316h-1.541"
                        transform="translate(1096.225 6255.895)"
                        fill="#231f20"
                      />
                      <path
                        d="M410.684-24.276h-1.926v5.283h1.926c2.063,0,3.191-1.145,3.191-2.652C413.875-23.183,412.706-24.276,410.684-24.276Zm-3.466,6.3V-25.3h3.466c3.15,0,4.759,1.63,4.759,3.653,0,2.218-1.871,3.673-4.759,3.673h-3.466"
                        transform="translate(1076.439 6255.92)"
                        fill="#231f20"
                      />
                      <path
                        d="M460.048-18.994v1.022h-6.7V-25.3h6.575v1.021H454.89v2.084h4.36v.959h-4.36v2.239h5.158"
                        transform="translate(1043.76 6255.92)"
                        fill="#231f20"
                      />
                    </g>
                    <line
                      y2="20"
                      transform="translate(1524.5 6208.5)"
                      fill="none"
                      stroke="#000"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              </Link>
            </div>
            <div className="items-center hidden xl:flex">
              <ul className="flex flex-row items-center space-x-6 text-sm">
                <li>
                  <a
                    href="https://www.forbestravelguide.com/health-security-verified"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline focus:underline"
                  >
                    {t('navigation.links.verified')}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.forbestravelguide.com/andiago-verified-restaurants"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline focus:underline"
                  >
                    {t('navigation.links.restaurants')}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.forbestravelguide.com/travel-advisors"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline focus:underline"
                  >
                    {t('navigation.links.endorsements')}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.forbestravelguide.com/verified-news"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline focus:underline"
                  >
                    {t('navigation.links.news')}
                  </a>
                </li>
              </ul>

              <div className="flex items-center justify-end h-16 pl-8 space-x-4">
                {user.authenticated ? (
                  <>
                    <Link
                      to="/dashboard/"
                      className="px-4 py-2 capitalize transition border-2 border-gray-300 rounded-md hover:bg-secondary-grey-light focus:bg-secondary-grey-light focus:outline-none"
                    >
                      {t('navigation.buttons.dashboard')}
                    </Link>
                    <Button onClick={() => handleSignout()}>
                      {t('navigation.buttons.signout')}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="secondary"
                      onClick={() => sign(false)}
                      gtag="Click_signin"
                      disabled={user.isAuthenticating || inProgress !== 'none'}
                      loading={user.isAuthenticating || inProgress !== 'none'}
                    >
                      {t('navigation.buttons.signin')}
                    </Button>
                    <Button
                      onClick={() => sign(true)}
                      gtag="Click_signup"
                      disabled={user.isAuthenticating || inProgress !== 'none'}
                      loading={user.isAuthenticating || inProgress !== 'none'}
                    >
                      {t('navigation.buttons.getStarted')}
                    </Button>
                  </>
                )}
              </div>
              {GATSBY_DEPLOYMENT !== 'PREPROD' && GATSBY_DEPLOYMENT !== 'PROD' && (
                <div className="relative" role="dialog" onMouseLeave={() => setDropdownOpen(-1)}>
                  <button
                    type="button"
                    onClick={() => setDropdownOpen(1)}
                    className="h-16 text-secondary hover:text-opacity-50 focus:text-opacity-50 focus:outline-none"
                  >
                    <div className="flex items-center justify-center w-full h-full px-4 rounded-full">
                      <small className="capitalize">{i18n.language.slice(0, 2)}</small>
                      <svg viewBox="0 0 8 5" className="w-4 h-4 pl-1 fill-current">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3.64072 4.84701L0.143713 1.35001C-0.0479042 1.14641 -0.0479042 0.835035 0.143713 0.643418C0.335329 0.451802 0.646707 0.451802 0.850299 0.643418L4 3.79312L7.1497 0.643418C7.34132 0.451802 7.66467 0.451802 7.85629 0.643418C8.0479 0.835035 8.0479 1.14641 7.85629 1.35001L4.34731 4.84701C4.15569 5.03863 3.84431 5.03863 3.64072 4.84701Z"
                        />
                      </svg>
                    </div>
                  </button>
                  <div
                    className="absolute right-0 bg-white border"
                    style={{ top: '4rem', display: dropdownOpen === 1 ? 'block' : 'none' }}
                  >
                    <ul className="flex flex-col items-center w-48">
                      <li className="w-full">
                        <button
                          type="button"
                          tabIndex={dropdownOpen === 1 ? '0' : '-1'}
                          onClick={() => {
                            i18n.changeLanguage('en-US');
                            setDropdownOpen(-1);
                          }}
                          className="flex items-center justify-center w-full py-2 whitespace-nowrap hover:bg-secondary hover:bg-opacity-10 focus:bg-secondary focus:bg-opacity-10 focus:outline-none"
                        >
                          English
                        </button>
                      </li>
                      <li className="w-full">
                        <button
                          type="button"
                          tabIndex={dropdownOpen === 1 ? '0' : '-1'}
                          onClick={() => {
                            i18n.changeLanguage('fr-FR');
                            setDropdownOpen(-1);
                          }}
                          onBlur={() => setDropdownOpen(-1)}
                          className="flex items-center justify-center w-full py-2 whitespace-nowrap hover:bg-secondary hover:bg-opacity-10 focus:bg-secondary focus:bg-opacity-10 focus:outline-none"
                        >
                          Français
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div className="flex items-center xl:hidden">
              <button
                type="button"
                onClick={() => setMenuOpen(true)}
                className="p-1 transition hover:text-primary-light-hover focus:text-primary-light-hover focus:outline-none"
              >
                <svg viewBox="0 0 24 24" className="w-6 h-6 fill-current">
                  <path d="M20 8H4V6h16v2zm0 5H4v-2h16v2zm0 5H4v-2h16v2z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </nav>
      <Menu
        open={menuOpen}
        requestClose={() => setMenuOpen(false)}
        sign={sign}
        signOut={handleSignout}
      />
    </>
  );
};

export default Navigation;
