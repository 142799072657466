import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from '@reach/router';

import { Aside, Button } from 'src/components';

const Menu = ({ open, requestClose, sign, signOut }) => {
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation('equilibre');
  const [language, setLanguage] = useState('en-US');
  const { search } = useLocation();
  const { GATSBY_DEPLOYMENT } = process.env;

  if (language !== 'fr-FR') {
    i18n.changeLanguage('fr-FR');
    setLanguage('fr-FR');
  }

  // trick to allow signup when passing ?signup
  const signupAllowed = search === '?signup' ||
    (GATSBY_DEPLOYMENT !== 'PROD' && GATSBY_DEPLOYMENT !== 'QA');

  return (
    <Aside title="Menu" open={open} requestClose={requestClose}>
      <Aside.Header closeButton requestClose={requestClose} />
      <Aside.Body>
        <nav className="text-center">
          <div className="flex flex-col px-4 pt-12 space-y-4">
            {user.authenticated ? (
              <>
                <Button as="link" variant="secondary" to="/dashboard/">
                  {t('navigation.buttons.dashboard')}
                </Button>
                <Button onClick={() => signOut()}>{t('navigation.buttons.signout')}</Button>
              </>
            ) : (
              <>
                <Button
                  variant="secondary"
                  gtag="Click_signin"
                  onClick={() => sign(false)}
                  disabled={user.isAuthenticating}
                  loading={user.isAuthenticating}
                >
                  {t('navigation.buttons.signin')}
                </Button>
                {
                  signupAllowed && (
                    <Button
                      gtag="Click_signup"
                      onClick={() => sign(true)}
                      disabled={user.isAuthenticating}
                      loading={user.isAuthenticating}
                    >
                      {t('navigation.buttons.getStarted')}
                    </Button>
                  ) 
                }
              </>
            )}
          </div>
        </nav>
      </Aside.Body>
    </Aside>
  );
};

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  sign: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default Menu;
