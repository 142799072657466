import VERTICALS from '../../constants/verticals.json';
import { FLOWS, MSAL } from '../shared';

const DEPLOYMENT = process.env.GATSBY_DEPLOYMENT;

let { AUTHORITY, SCOPES } = MSAL[DEPLOYMENT];

if (DEPLOYMENT === 'PROD') {
  AUTHORITY = 'https://sharecareeuprod.b2clogin.com/sharecareeuprod.onmicrosoft.com';
  SCOPES = [
    'https://sharecareeuprod.onmicrosoft.com/68518e7a-1999-46ff-aa45-ecbbe3c58cea/read',
    'https://sharecareeuprod.onmicrosoft.com/68518e7a-1999-46ff-aa45-ecbbe3c58cea/write',
  ];
}

const vulcainConfig = {
  VERTICAL: VERTICALS.VULCAIN,
  DEPLOYMENT: {
    LOCAL: {
      ...MSAL.LOCAL,
      API_URL: 'http://localhost:3000/api',
    },
    DEV: {
      ...MSAL.DEV,
      REDIRECT_URI: 'https://vulcain-amba-dev.hs.sharecare.paris',
      API_URL: 'https://vulcain.dev.hs.sharecare.paris/api',
    },
    QA: {
      ...MSAL.QA,
      REDIRECT_URI: 'https://vulcain.qa.hs.sharecare.paris',
      API_URL: 'https://vulcain.qa.hs.sharecare.paris/api',
    },
    PREPROD: {
      ...MSAL.PREPROD,
      REDIRECT_URI: 'https://vulcain.preprod.hs.sharecare.paris',
      API_URL: 'https://vulcain.preprod.hs.sharecare.paris/api',
    },
    PROD: {
      ...MSAL.PROD,
      CLIENT_ID: '886b72e4-3a6b-4d5b-b7e1-4677fbeb79b6',
      AUTHORITY,
      KNOWN_AUTHORITIES: ['sharecareeuprod.b2clogin.com'],
      REDIRECT_URI: 'https://vulcain.sharecare.fr',
      SCOPES,
      API_URL: 'https://amba-back.sharecare.fr/api',
    },
  }[process.env.GATSBY_DEPLOYMENT],
  FLOWS: {
    [FLOWS.SIGNIN]: {
      scopes: SCOPES,
      authority: `${AUTHORITY}/B2C_1_signin`,
    },
    [FLOWS.SIGNUP]: {
      scopes: SCOPES,
      authority: `${AUTHORITY}/B2C_1_signup`,
    },
    [FLOWS.PROFIL]: {
      scopes: SCOPES,
      authority: `${AUTHORITY}/B2C_1_profile_editing`,
    },
    [FLOWS.PASSWORD]: {
      scopes: SCOPES,
      authority: `${AUTHORITY}/B2C_1_password_reset`,
    },
  },
};

if (DEPLOYMENT === 'PROD') {
  // delete vulcainConfig.FLOWS[FLOWS.SIGNUP];
}

export default vulcainConfig;
