import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { useLocation } from '@reach/router';

import { setAuthenticating, signOut } from 'src/store';
import CONFIG from 'src/config';
import { FLOWS } from 'src/config/shared';
import { Button } from 'src/components';

import Menu from './Menu';

const Navigation = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { pathname } = useLocation();
  const isLoginPage = pathname === '/';
  const { instance, inProgress } = useMsal();

  const { t, i18n } = useTranslation('common');
  const [menuOpen, setMenuOpen] = useState(false);

  const sign = async (signup = false) => {
    localStorage.setItem('redirect', 'true');
    dispatch(setAuthenticating(true));

    const redirectRequest = CONFIG.FLOWS[signup ? FLOWS.SIGNUP : FLOWS.SIGNIN];
    const lang = i18n.language.slice(0, 2);

    await instance.loginRedirect({
      ...redirectRequest,
      extraQueryParameters: { lang },
    });
  };

  const handleSignout = async () => {
    dispatch(signOut())
      .then(async () => instance.logout())
      .catch(async () => instance.logout());
  };

  return (
    <>
      <nav className="fixed inset-x-0 top-0 z-50 bg-white shadow-md">
        <div className="container">
          <div className="flex items-center justify-between w-full h-16">
            <div className="flex">
              <svg viewBox="0 0 272.697 24.246" className="w-2/3 h-12">
                <g transform="translate(-229.149 -374)">
                  <g transform="translate(229.149 373.123)">
                    <path
                      d="M36.311,12.383v-.12a5.741,5.741,0,0,0-.66-2.89c-1.45-2.54-4.77-2.07-4.77-2.07v9.94S36.251,17.993,36.311,12.383Zm4.9,0V19.8h7.78v-2.55h-4.81v-4.09h4.65v-2.57h-4.65V7.3H49V4.733h-7.78v7.64Zm10.33,0V19.8h3V13.1l4.84,6.7h3.85l-5.62-7.42-.49-.64,1.91-2.35,3.8-4.66h-3.61l-3.5,4.65-1.21,1.63V4.723h-3v7.65Zm16-3v2.26h.71a2.25,2.25,0,0,0,2.44-2.26,2.16,2.16,0,0,0-2.44-2.21h-.71v2.21Zm12.07,4.81,1.92-5.45,1.87,5.45Zm3.58-8.09c-.88-2.34-2.44-2.27-3.32,0s-4.73,12.49-5.15,13.59l-4.18-6.23a4.05,4.05,0,0,0,1.94-1.12,4.28,4.28,0,0,0,1.07-3s.37-4.65-4.84-4.65h-4.14v15.07h3v-6.05l3.7,6.05h6.4l1.14-3.16H84.1l1.25,3.16h3s-4.26-11.36-5.14-13.69Zm-55.31,3.28V4.723H32c4.41,0,6.19,2.53,6.91,4.66a9.542,9.542,0,0,1,.48,2.88v.09c0,.94-.31,7.47-7.39,7.44h-4.13V9.383Z"
                      fill="#347a46"
                      fillRule="evenodd"
                    />
                    <path
                      d="M.05,25.123v-4.43l15.36-9.06-8.3-4.91v7.05L3.3,15.853V2.293c0-1.31.83-1.73,1.8-1.18s8.86,5.11,11.9,6.92c3.25,1.94,3,5.67,0,7.33s-17,9.76-17,9.76Z"
                      fill="#347a46"
                      fillRule="evenodd"
                    />
                  </g>
                  <g transform="translate(-1197 -5827)">
                    <path
                      d="M16.095,17.6A10.078,10.078,0,0,1,10,19.628,9.908,9.908,0,0,1,0,9.814,9.908,9.908,0,0,1,10,0,9.908,9.908,0,0,1,20,9.814a9.664,9.664,0,0,1-2.192,6.134l4.5,3.681H16.923ZM10,17.364a7.622,7.622,0,0,0,7.692-7.55A7.622,7.622,0,0,0,10,2.265,7.622,7.622,0,0,0,2.308,9.814,7.622,7.622,0,0,0,10,17.364Zm16.594-2.456h2.438c-.208,3.3,6.062,3.418,6.167.95,0-2.537-4.877-.923-7.519-3.516-1.793-1.569-1.845-6.465,3.791-6.6a7.087,7.087,0,0,1,3.377.777V5.969h2.41v4.5H34.846V9.63a3.619,3.619,0,0,0-3.232-1.674c-1.467,0-2.435.665-2.435,1.674,0,2.278,5.345.951,7.636,3.2,2.8,3.027.442,7.135-4.14,6.976a6.272,6.272,0,0,1-3.643-.979v.751H26.593V14.908ZM41.369,2.365h-2.5V0h5.259V7.525a7.213,7.213,0,0,1,4.611-1.96c2.172,0,3.818,1.065,3.818,4.123v7.525h2.382v2.365H49.8V10.264c0-1.326-.41-2.308-1.7-2.308a7.608,7.608,0,0,0-3.965,1.79v7.468h2.377v2.365H38.842V17.214h2.527V2.365ZM69.775,19.58H64.957V17.9c-3.866,3.513-8.988,1.861-9.163-2.423-.011-3.1,3.89-6.158,8.842-3.257-.088-1.771.637-4.243-3.085-4.412a3.223,3.223,0,0,0-2.968,1.618l-2.615-.7C58.16,4.208,67.532,4.5,67.4,9.746v7.469h2.38v2.364ZM64.635,14.5c-1.716-1.488-5.908-2.4-6.228.978.264,3.236,4.343,2.492,6.228.635V14.5Zm9.487-6.172H71.331V5.968h4.964v3.1a4.8,4.8,0,0,1,4.437-3.328h1.79v2.71H81.052c-5.222.076-4.171,4.385-4.171,8.765h3v2.365H71.389V17.215h2.732V8.331Zm10.824,5.105c.739,5.138,6.858,5.2,9.208,1.96l2.4,1.038c-4.16,6.19-14.709,3.906-14.615-3.662a7.206,7.206,0,0,1,7.247-7.208c3.832,0,7.368,2.885,7.368,7.872Zm8.828-1.963a4.687,4.687,0,0,0-4.529-3.748,4.231,4.231,0,0,0-4.212,3.748Z"
                      transform="translate(1545 6203)"
                      fill="#333"
                      fillRule="evenodd"
                    />
                    <path
                      d="M16.769,9.9c0,1.98-2.012,4.584-5.385,6.788C8.2,14.608,6,11.85,6,9.9c.073-3.512,4.372-3.666,5.384-1.962C12.432,6.23,16.706,6.358,16.769,9.9Zm126.856,5.125c.74,5.138,6.858,5.2,9.208,1.959l2.4,1.038c-4.16,6.191-14.709,3.906-14.615-3.662a7.206,7.206,0,0,1,7.248-7.208c3.831,0,7.368,2.885,7.368,7.872H143.626ZM132.259,9.915h-2.79V7.552h4.964v3.1a4.8,4.8,0,0,1,4.437-3.327h1.79v2.71h-1.469c-5.222.075-4.171,4.385-4.171,8.765h3v2.365h-8.492V18.8h2.732V9.915ZM127.913,21.17h-4.818V19.495c-3.866,3.514-8.99,1.862-9.165-2.423-.01-3.1,3.89-6.157,8.842-3.256-.087-1.771.638-4.243-3.085-4.412a3.222,3.222,0,0,0-2.967,1.618l-2.615-.7c2.192-4.527,11.563-4.238,11.428,1.01v7.469h2.38Zm24.541-8.112a4.689,4.689,0,0,0-4.531-3.748,4.232,4.232,0,0,0-4.211,3.748Zm-29.682,3.035c-1.715-1.488-5.908-2.4-6.227.979.262,3.235,4.342,2.492,6.227.635Zm-10.635-3.407H109.67A3.465,3.465,0,0,0,106,9.6c-2.468,0-4.114,1.873-4.114,4.612.308,6.442,6.936,5.838,8.4,2.22l2.263,1.038a6.71,6.71,0,0,1-6.525,4.094c-4.815,0-7.077-3.865-7.077-7.323a6.938,6.938,0,0,1,6.815-7.092,6.036,6.036,0,0,1,3.908,1.325V7.553h2.466Z"
                      transform="translate(1543.615 6201.415)"
                      fill="#1aba9c"
                      fillRule="evenodd"
                    />
                  </g>
                  <line
                    y2="20"
                    transform="translate(332.5 376.5)"
                    fill="none"
                    stroke="#000"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </div>
            <div className="flex items-center justify-end h-16 pl-8 space-x-4">
              {isLoginPage && user.authenticated && (
                <>
                  <Link
                    to="/dashboard/"
                    className="px-4 py-2 capitalize transition border-2 border-gray-300 rounded-md hover:bg-secondary-grey-light focus:bg-secondary-grey-light focus:outline-none"
                  >
                    {t('navigation.buttons.dashboard')}
                  </Link>
                  <Button onClick={() => handleSignout()}>{t('navigation.buttons.signout')}</Button>
                </>
              )}
              {isLoginPage && !user.authenticated && (
                <>
                  <Button
                    variant="secondary"
                    onClick={() => sign(false)}
                    gtag="Click_signin"
                    disabled={user.isAuthenticating || inProgress !== 'none'}
                    loading={user.isAuthenticating || inProgress !== 'none'}
                  >
                    {t('navigation.buttons.signin')}
                  </Button>
                  <Button
                    onClick={() => sign(true)}
                    gtag="Click_signup"
                    disabled={user.isAuthenticating || inProgress !== 'none'}
                    loading={user.isAuthenticating || inProgress !== 'none'}
                  >
                    {t('navigation.buttons.getStarted')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>

      <Menu
        open={menuOpen}
        requestClose={() => setMenuOpen(false)}
        sign={sign}
        signOut={handleSignout}
      />
    </>
  );
};

export default Navigation;
