import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Richtext } from 'src/components';

const Accordion = ({ title, content, gtagID, defaultOpen }) => {
  const [active, setActive] = useState(defaultOpen);
  const [height, setHeight] = useState('0px');
  const [rotate, setRotate] = useState('transform duration-700 ease');

  const contentSpace = useRef(null);

  function toggleAccordion() {
    setActive(!active);
    // if (window.gtag && !active) window.gtag('event', `${gtagID}_open`);
    setHeight(active ? '0px' : `${contentSpace.current.scrollHeight}px`);
    setRotate(active ? 'transform duration-700 ease' : 'transform duration-700 ease rotate-180');
  }

  return (
    <div
      className="flex flex-col my-1 border-2 rounded-2xl cursor-pointer"
      onClick={toggleAccordion}
    >
      <button
        className={`px-4 lg:px-8 py-5 box-border appearance-none focus:outline-none flex items-center justify-between bg-white ${
          active ? 'rounded-t-2xl' : 'rounded-2xl'
        }`}
      >
        <div className="flex flex-row flex-grow gap-4 text-base font-bold text-left">
          <div className="flex-col flex-auto self-center">
            <Richtext content={title} />
          </div>
          <div className="flex-col flex-none self-center">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-down"
              className={`svg-inline--fa fa-chevron-down fa-w-14 ${rotate} inline-block w-4 lg:w-4`}
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              ></path>
            </svg>
          </div>
        </div>
      </button>
      <div
        ref={contentSpace}
        style={{ maxHeight: `${height}` }}
        className={`px-5 lg:px-8 text-left transition-max-height duration-700 ease-in-out bg-white rounded-b-2xl ${
          height ? 'overflow-hidden' : ''
        }`}
      >
        <div className="pb-10">
          <Richtext content={content} />
        </div>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  defaultOpen: PropTypes.bool,
};

Accordion.defaultProps = {
  defaultOpen: false,
};

export default Accordion;
