import React from 'react';
import PropTypes from 'prop-types';

import { Navigation, Footer } from './components';

const Layout = ({ children }) => {
  const closeChatbot = () => {
    document.getElementById('schools-chatbot').classList.remove('open');
  };

  return (
    <>
      <Navigation />
      {children}
      <Footer />
      <div id="schools-chatbot">
        <div className="flex justify-end" style={{ padding: '5px' }}>
          <button type="button" onClick={() => closeChatbot()} className="pr-2">
            <svg
              viewBox="0 0 24 24"
              className="w-6 h-6 text-gray-500 stroke-current focus:outline-none"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
                fill="none"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
