import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import useSWR from 'swr';

import { closeQuote } from 'src/store';
import { fetchOnce, formatBilling } from 'src/helpers';
import CONFIG from 'src/config';
import { FLOWS } from 'src/config/shared';
import { Modal, Multistep, Spinner } from 'src/components';
import {
  StepPackage,
  StepContract,
  StepContractNew,
  StepCheckout,
  packageInitialValues,
  contractInitialValues,
  contractInitialValuesNew,
  checkoutInitialValues,
  packageSchema,
  contractSchema,
  checkoutSchema,
} from './components';

import { httpClient } from 'src/helpers';

const ModalQuote = () => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const { quote } = useSelector((state) => state.modal);

  const [ref, setRef] = useState(null);

  const error = null;
  const [data, setData] = useState();

  const initSwitchState = {
    mo3: { selected: false },
    mo6: { selected: false },
  };
  const [switches, setSwitches] = useState(initSwitchState);

  const requestClose = () => dispatch(closeQuote());

  const handleSubmit = async ({ employees, product, billing }) => {
    const formatedBilling = formatBilling(billing);
    localStorage.setItem(
      'quote',
      JSON.stringify({ product: { name: product.name, employees }, billing: formatedBilling })
    );

    setTimeout(() => {
      instance.loginRedirect(CONFIG.FLOWS[FLOWS.SIGNUP]);
    }, 1500);
  };

  useEffect(() => {
    setTimeout(() => {
      setRef(localStorage.getItem('ref') || '');
      getOffers().then(() => {
        console.log('Get Quote Data');
      });
    }, 1000);
  }, []);

  const getOffers = async (nbUnits) => {
    const formatedValues = {
      endorserName: ref || '', // the name of the endorser (e.g.: Polsinelli)
      productName: 'hsp', // the name of the product (e.g.: hsp)
      userSettings: { nbUnits: nbUnits }, // the user data (e.g.: { "nbUnits": 100 })
    };

    try {
      const resp = await httpClient.post(`/products/offers`, formatedValues);

      if (resp.status === 200) {
        setData(resp.data);
      } else {
        throw Error('Offers failed.');
      }
    } catch (error) {
      console.log('Offers failed.');
    }
  };

  return (
    <Modal
      open={quote}
      requestClose={requestClose}
      closeOnEsc={false}
      closeOnOverlay={false}
      maxWidth
    >
      {!data && !error && (
        <div className="flex items-center justify-center py-24 text-primary-light">
          <div className="h-12">
            <Spinner />
          </div>
        </div>
      )}
      {error && <div>An error occured.</div>}
      {data && !error && (
        <Multistep
          initialValues={{
            ...packageInitialValues,
            ...contractInitialValuesNew,
            ...checkoutInitialValues,
          }}
          handleSubmit={handleSubmit}
          requestClose={requestClose}
          fixed={
            <div className="flex flex-col items-center justify-center pb-12 text-center">
              <h5 className="text-xl font-bold lg:text-3xl">Start with Health Security Pass</h5>
              <span className="pt-2 text-gray-500">
                Subscribe to Health security pass to access the product
              </span>
            </div>
          }
          labels={{
            next: 'Continue',
            last: 'Confirm & Sign Up',
          }}
        >
          <StepPackage schema={packageSchema} />
          <StepContractNew
            products={data}
            switches={switches}
            setSwitches={setSwitches}
            getOffers={getOffers}
          />
          <StepCheckout schema={checkoutSchema} />
        </Multistep>
      )}
    </Modal>
  );
};

export default ModalQuote;
