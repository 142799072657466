import React, { useState } from 'react';
import PropTypes from 'prop-types';

import VulcainLogo from 'src/assets/images/vulcain-logo-chatbot.svg';
import { httpClient } from 'src/helpers';
import CONFIG from 'src/config';
import { FLOWS } from 'src/config/shared';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { setAuthenticating } from 'src/store';
import { useTranslation } from 'react-i18next';
import { Navigation, Footer } from './components';



const Layout = ({ children }) => {
  const closeChatbot = () => {
    document.getElementById('vulcain-chatbot').classList.remove('open');
  };

  const dispatch = useDispatch();
  const { instance } = useMsal();
  const { i18n } = useTranslation('vulcain');
  const lang = i18n.language.slice(0, 2);

  const isBrowser = () => typeof window !== "undefined"
  const searchParams = new URLSearchParams(isBrowser() && `URLSearchParams` in window ? isBrowser() && window.location.search : '')
  const email = searchParams.get('email') || null
  const secret = searchParams.get('secret') || null
  const [password, setPassword] = useState("");
  const [error, setError] = useState('');

  const submitPassword = async () => {
    try {
      // clear error field
      setError('')

      // call password set endpoint; if an error occurs, display an error message
      const res = await httpClient.post(`/users/set-password`, { email, secret, password });
      if (!res.data?.success) {
        setError('An unexpected error occured. Please contact your administrator');
        return;
      }

      // start the automagic signin process
      dispatch(setAuthenticating(true));
      localStorage.setItem('redirect', 'true');
      localStorage.setItem('automagic', 'true');
      const redirectRequest = CONFIG.FLOWS[FLOWS.SIGNIN];
      const extraQueryParameters = { lang, automagic: `${btoa(email)}:${btoa(password)}` };
      await instance.loginRedirect({ ...redirectRequest, extraQueryParameters });
    } catch (e) {
      setError(e?.response?.data || JSON.stringify(e));
    }
  };

  return (
    <>
      {email &&
        <div id="vulcain_set_password">
          <div>
            <div className="logo">
              <img src="https://storageambafrontdev.blob.core.windows.net/dev/auth/logo.svg" alt="Sharecare" />
              <h4>All together better.</h4>
            </div>
            <p>
              <input type="text" id="email" name="email" disabled="disabled" value={email} />
            </p>
            <p>
              <input type="password" id="password" name="password" autoComplete="false" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Veuillez choisir un mot de passe" />
            </p>
            <p style={{ fontSize: '.7em;' }}>Au minimum 8 caractères, avec au moins 3 types parmi minuscule, majuscule, chiffre et caractère spécial.</p>
            <input type="hidden" name="secret" disabled="disabled" value={email} />
            <p>
              <button type="button" onClick={submitPassword} className="flex items-center justify-center p-3 rounded-full bg-secondary-grey-light">Se connecter</button>
            </p>
            {error && (
              <p style={{ color: 'red' }}>{error}</p>
            )}
          </div>
        </div>
      }
      {!email &&
        <>
          <Navigation />
          {children}
          <Footer />
          <div id="vulcain-chatbot">
            <div className="flex justify-between" style={{ padding: '5px' }}>
              <img src={VulcainLogo} alt="Vulcain" style={{ maxHeight: '26px' }} />
              <button type="button" onClick={() => closeChatbot()} className="pr-2">
                <svg
                  viewBox="0 0 24 24"
                  className="w-6 h-6 text-gray-500 stroke-current focus:outline-none"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                    fill="none"
                  />
                </svg>
              </button>
            </div>
          </div>
        </>
      }
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
