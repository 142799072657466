import VERTICALS from 'src/constants/verticals.json';

const mapRoles = (role) => {
  const { GATSBY_VERTICAL } = process.env;

  if (GATSBY_VERTICAL === VERTICALS.VACCINE) {
    switch (role) {
      case 'ADMIN':
        return 'Admin';
      case 'MANAGER':
        return 'Site manager';
      default:
        return 'Provider';
    }
  } else if (GATSBY_VERTICAL === VERTICALS.GREENSHIELD) {
    switch (role) {
      case 'ADMIN':
        return 'Admin';
      case 'MANAGER':
        return 'Manager';
      default:
        return 'Supervisor';
    }
  } else if (GATSBY_VERTICAL === VERTICALS.VULCAIN) {
    switch (role) {
      case 'ADMIN':
        return 'Admin';
      case 'MANAGER':
        return 'Manager';
      default:
        return 'Collaborateur';
    }
  } else {
    switch (role) {
      case 'SUPERADMIN':
        return 'Super admin';
      case 'ADMIN':
        return 'Admin';
      case 'MANAGER':
        return 'DRM';
      default:
        return 'SME';
    }
  }
};

export default mapRoles;
